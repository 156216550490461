import React from 'react'
import styled from 'styled-components'

const TextWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  margin-top: 2rem;
  padding: 1rem;
  width: 640px;
`

export default TextWrapper