import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextWrapper from "../components/text-wrapper"
import Title from "../components/title"
import instagramIcon from "../images/instagram-icon.svg"

const AboutPage = ({ data }) => (
  <Layout>
    <SEO title="Bordar BA | About" />
    <Title>Sobre Bordar BA</Title>
    <TextWrapper>
      <P>Bordar BA nace de las ganas de dos amigas y su pasión por el bordado. Nos conocimos y descubrimos que teníamos muchas cosas en común, entre ellas bordar. Tuvimos el deseo de generar un espacio de encuentro con otras personas que también amaran pasar tiempo entre hilos y agujas una vez por mes. Así, organizamos la primera meet up una tarde fría de invierno. </P>
      <P>Desde entonces, organizamos encuentros en distintos puntos de Buenos Aires para juntarnos a bordar, tejer o hacer otro tipo de actividades manuales acompañadas. 
      Bordar BA es un espacio de reunión y todes son bienvenides pero no es un espacio de aprendizaje. Si lo que te interesa es aprender a bordar, con gusto te sugerimos algunos lugares donde podrás hacerlo.</P>
      <IconWrapper>
        <a href="https://www.instagram.com/bordar.ba/" target="_blank" noopener noreferrer>
          <Icon src={instagramIcon} />
        </a>
      </IconWrapper>
    </TextWrapper>
  </Layout>
)

const P = styled.p`
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 600px;
`

const Icon = styled.img`
  height: 25px;
  width: 25px;
`

export default AboutPage