import React from 'react'
import styled from 'styled-components'

const Title = styled.h2`
  background-color: rgba(255, 255, 255, 0.2);
  color: black;
  display: inline-block;
  padding: 0.5rem;
  text-transform: uppercase;
`

export default Title